<template>
  <div>

    <Header/>


    <div class="tw-p-32">

      <div class="tw-mb-10 md:tw-mb-1 tw-flex tw-justify-start tw-items-start">
        <v-icon class="tw-pr-3 align-start justify-start ">mdi-arrow-left</v-icon>
        <span>Back</span>
      </div>

      <div class="justify-center align-center tw-items-center tw-justify-center tw-flex">
        <div class="badgeContainer align-center tw-items-center tw-justify-center">
          <div class="tw-items-center tw-justify-center align-center tw-flex">
            <img :src="background" alt=""/>
          </div>
          <div class="badge tw-items-center tw-justify-center align-center tw-flex tw-mb-10">
            <img :src="badge" alt=""/>
          </div>

          <div class="skill tw-justify-center tw-flex">
            <h2>{{badgeDetails.question_area}} Skill Verified</h2>
          </div>

          <v-divider class="tw-mx-12 tw-mt-5"/>

          <h5 class="for tw-mt-8">for</h5>
          <h4 class="name tw-mt-6">{{badgeDetails.name}}</h4>
          <h6 class="content tw-mt-6 tw-w-96 tw-m-auto">The bearer of this certificate has passed {{badgeDetails.question_area}} assessment and hereby deemed fully capable for a position in this skill area.</h6>

          <div class="tw-flex tw-justify-center tw-space-x-10 md:tw-space-x-52 tw-mt-16 tw-mb-12">
            <div class="verification tw-w-52">Verified on {{formatDate(badgeDetails.date_created)}}</div>
            <div class="verification tw-w-28">enum.africa</div>
          </div>
        </div>
      </div>
    </div>


    <div class="tw-p-4 justify-center align-center tw-items-center tw-justify-center tw-mt-5 md:tw-mt-0 tw-flex">
      <div class="linkSection">
        <div class="md:tw-flex tw-w-4/5 tw-mt-9 tw-items-center tw-m-auto tw-mb-9">
          <div class="linkBox md:tw-w-80 tw-mb-3 md:tw-mb-0"></div>
          <div class="md:tw-pl-5">
            <BaseButton button-text="Copy link to share" width="187" class="tw-pl-10 " :block="$vuetify.breakpoint.mobile"/>
          </div>
        </div>

      </div>
    </div>



  </div>
</template>

<script>
import Header from "@/components/reusables/Header";
import BaseButton from "@/components/reusables/BaseButton";
import {getBadgeDetail} from "@/services/api/APIService";
import moment from "moment";

export default {
  name: "enumBadge",
  components: {BaseButton, Header},
  data() {
    return {
      background: require('/src/assets/images/blueWaves.svg'),
      badge: require('/src/assets/images/badgeLogo.svg'),

      badgeDetails: {
        id: 10,
        question_area: "Financial Analytic",
        date_created: "2022-06-14T15:54:23.330824+01:00",
        name: "Micheal Adams"
      }
    }
  },

  methods: {
    getDetails() {
      const id = this.$route.query.badge_id
      getBadgeDetail(id).then(res => {
        this.badgeDetails = res.data
      })
    }
  },
  computed: {
    formatDate() {
      return function (date) {
        return moment(date).format('Do MMMM YYYY');
      }
    }
  }
}
</script>

<style scoped>
.badgeContainer {
  /*position: absolute;*/
  width: 637px;
  height: 685px;
  /*left: 402px;*/
  /*top: 139px;*/
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.badge {
  margin-top: -6rem;
}

.skill {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661;
}

.tw-mx-12 {
  color: #E0E9FF;
}

.for {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #9CABB5;
}

.name {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #475661;
}

.content {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #9CABB5;
}

.verification {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}

.linkSection {
  width: 637px;
  /*height: 101px;*/
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: -9rem;
}

.linkBox{
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #9CABB5;
  border-radius: 6px;
}
</style>