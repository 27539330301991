<template>
  <v-app-bar color="white" fixed elevation="1" height="76">
    <div :class="['applyFlexBetween',  'tw-w-full', $vuetify.breakpoint.mobileBreakpoint?'tw-mb-10':'' ]">
      <div class="tw-hidden lg:tw-block">
        <EnumWithWordIcon @click="goToDashboard"/>
      </div>
      <div class="lg:tw-hidden">
        <slot name="route-back-component">
          <EnumWithoutWordIcon @click="goToDashboard"/>
        </slot>
      </div>
      <div v-if="$route.name !== 'Vetting'">
        <div v-if="$route.name !== 'OfferDetailsState'|| $route.name !== 'CreateSpecializeProfile' ||
       $route.name !=='ViewCompanyProfile'" class="applicantHeaderSearch lg:tw-block ">
          <div class="search">
            <v-text-field v-if="!$vuetify.breakpoint.mobile && $route.name !== 'SearchResult'" :loading="isLoading"
                          v-model="search" hide-details placeholder="Search" solo
                          prepend-inner-icon="mdi-magnify" @keydown.enter.prevent="searchJob()"/>
            <div v-else>
              <v-text-field :loading="isLoading" v-model="search" full-width placeholder="Search" hide-details solo
                            clearable
                            :class="['mx-6',$vuetify.breakpoint.mobile && $route.query.jobId?'tw-hidden':'tw-block']"
                            prepend-inner-icon="mdi-magnify" @keydown.enter.prevent="searchJob()"/>
            </div>

          </div>
        </div>
      </div>

      <div v-if="!$vuetify.breakpoint.mobile">
        <slot name="right-header-component">
        </slot>
      </div>
      <div v-if="$route.name === 'CreateSpecializeProfile'">
        <slot name="right-header-component-specialize">
        </slot>
      </div>
    </div>
  </v-app-bar>
</template>

<script>

import EnumWithWordIcon from "@/components/icons/EnumWithWordIcon";
import EnumWithoutWordIcon from "@/components/icons/EnumWithoutWordIcon";
import {mapGetters} from "vuex";


export default {
  name: "Header",
  components: {EnumWithoutWordIcon, EnumWithWordIcon},
  data() {
    return {
      loading: false,
      switchRoute: true,
      search: "",
      isMobile: false
    }
  },
  computed: {
    ...mapGetters('jobSearch', ['isLoading']),
    showJob() {
      return this.$route.name === "Jobs" ? this.switchRoute : !this.switchRoute
    },
    isLogged() {
      const user = JSON.parse(sessionStorage.getItem("userData"));
      console.log(user)
      return user

    }


  },
  // created() {
  //
  //   if(this.search === ""){
  //     this.searchJob("engineer")
  //   }
  //   else {
  //     this.searchJob(this.search)
  //   }
  // },

  created() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
      const data = {}
      data.query = this.search
      if (!this.isLogged) {
        this.$store.dispatch('jobSearch/searchJobs', data)
      } else {
        data.email = this.isLogged.email
        this.$store.dispatch('jobSearch/searchJobs', data)
      }

    }
  },

  methods: {
    searchJob() {
      const data = {}
      let userDetails = JSON.parse(sessionStorage.getItem('userData'))
      data.query = this.search
      data.page_size = 12
      if (userDetails === null) {
        this.$store.dispatch('jobSearch/searchJobs', {payload: data, $route: this.$route})
      } else {
        data.email = userDetails.email
        this.$store.dispatch('jobSearch/searchJobs', {payload: data, $route: this.$route})
      }
      this.$emit('click', data.query)
    },
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    async goToDashboard() {
      await this.$router.push({name: 'Dashboard'})
    }
  }
}
</script>

<style scoped lang="scss">

.v-application .elevation-1 {
  box-shadow: 0px 2px 16px #F0F9FF !important;
}

.applicantHeaderSearch {
  width: 383px;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
